<template>
  <div>
    <!--    <div id="footer-wrap01">-->
    <!--      <div class="container">-->
    <!--        <div class="policy">-->
    <!--          <a href=""><span class="trans">HOME</span></a>-->
    <!--          <a href=""><span class="trans">회사소개</span></a>-->
    <!--          <a href="">개인정보보호정책</a>-->
    <!--          <a href="">이메일무단수집거부</a>-->
    <!--          <a href=""><span class="trans">오시는길</span></a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <footer id="footer-wrap02">
      <div class="container">
        <div class="address">
          <div class="link-button">
            <img src="@/assets/images/facebook-link.png" alt="facebook" @click="linkGo(0)">
            <img src="@/assets/images/insta-link.png" alt="insta" @click="linkGo(1)">
            <img src="@/assets/images/cafe-link.png" alt="cafe" @click="linkGo(2)">
          </div>
          <h6><strong>주식회사 아쎄따</strong></h6>대표 : 김형준 <br><br class="hidden-fs">본사 주소 : 인천광역시 연수구 컨벤시아대로 204, 524호(송도동,
          인천스타트업파크)<br>연구소/공장
          주소: 인천광역시 서구 가재울로 109, DH비즈타워 901호/902호<br> 비행장/공장 주소 : 경기도 연천군 전곡읍 양원리 560 <br>고객지원 : 032-459-2286<br
            class="hidden-fs"> EMAIL : droneaz@hanmail.net <br class="hidden-fs">사업자등록번호 : 825-81-02008
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    linkGo(val) {
      let url;
      if (val === 0) {
        url = 'https://www.facebook.com/hyungjun.kim.186590'
      } else if (val === 1) {
        url = 'https://www.instagram.com/we_assetta/'
      } else {
        url = 'https://cafe.naver.com/droned2'
      }
      window.open(url, '_blank')
    },
  }
}
</script>

<style scoped>
#footer-wrap01 {
  width: 100%;
  position: relative;
  background-color: #111;
  padding: 17px 0 17px 0;
}

#footer-wrap02 {
  width: 100%;
  position: relative;
  clear: both;
  margin: 0;
  padding: 25px 0 40px 0;
  background-color: #303030;
  border-top: 0px solid #ddd;
}

.address {
  /*text-align: center;*/
  font-size: 14px;
  padding: 3px 0 5px 0;
  line-height: 24px;
  color: #919294;
  position: relative;
}

.policy {
  text-align: center;
  color: #ccc;
  font-size: 15px;
  margin: 0 0 0 0;
}

.policy a:link {
  color: #ccc;
  padding: 0 15px 0 15px;
  transition: 0.3s ease;
}

.policy a:visited {
  color: #ccc;
}

.policy a:hover {
  color: #fff;
}

.policy .trans {
  text-align: center;
}

.policy-line {
  color: #555;
  font-size: 10px;
  margin: 0 7px 0 7px;
}

.link-button {
  position: absolute;
  right: 0;
}

.link-button img {
  width: 30px;
  margin: 0 8px;
  cursor: pointer;
}
</style>
