<template>
  <div>
    <div class="nav-parent">
      <mdb-navbar class="nav" dark>
        <mdb-navbar-brand to="/">
          <img src="../assets/images/logo-police-lab_w.png" alt="" style="width: 277px; height: 29px">
        </mdb-navbar-brand>
        <mdb-navbar-toggler>
          <mdb-navbar-nav center class="font-weight-bold">
            <mdb-nav-item class="nav-item-text" href="#/businessView">사업 소개</mdb-nav-item>
<!--            <mdb-dropdown tag="li" class="nav-item">-->
<!--              <mdb-dropdown-toggle class="nav-item-text" tag="a" navLink slot="toggle" waves-fixed>아이디어 접수</mdb-dropdown-toggle>-->
<!--              <mdb-dropdown-menu>-->
<!--                <mdb-dropdown-item href="#/ideaList">2022년도</mdb-dropdown-item>-->
<!--              </mdb-dropdown-menu>-->
<!--            </mdb-dropdown>-->
            <mdb-nav-item class="nav-item-text" href="#/noticeList">공지 사항</mdb-nav-item>
            <mdb-nav-item class="nav-item-text" href="#/ideaList">아이디어 접수</mdb-nav-item>
            <mdb-nav-item class="nav-item-text" href="#/requestForm">시제품 의뢰</mdb-nav-item>
            <mdb-nav-item class="nav-item-text" href="#/technicalList">기술 문의</mdb-nav-item>
          </mdb-navbar-nav>
          <router-link to="/user/login" v-if="$store.state.login === false">
            <button class="login-btn">로그인</button>
          </router-link>
          <!--        <p class="login-email" v-if="$store.state.login === true">{{ $store.state.user_email }}</p>-->
          <button class="login-btn" v-if="$store.state.login === true" @click="logout">로그아웃</button>
        </mdb-navbar-toggler>
      </mdb-navbar>
    </div>
  </div>
</template>

<script>
import {
  // mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarNav,
  mdbNavbarToggler, mdbNavItem,
} from "mdbvue";
import firebase from "firebase/app";

export default {
  name: "Nav",
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    // mdbDropdown,
    // mdbDropdownMenu,
    // mdbDropdownToggle,
    // mdbDropdownItem,
    mdbNavItem,
  },
  methods: {
    logout() {
      const self = this;
      firebase.auth().signOut().then(() => {
        self.$store.commit('setLogout')
        self.$router.replace('/');
      }).catch((error) => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>
.nav-parent {
  background-image: linear-gradient(180deg, #25386a, #2b4999);
  height: 124px;
  position: relative;
  z-index: 6000;
  border-bottom: solid 1px #ffffff;
}

.nav {
  background-image: linear-gradient(180deg, #25386a, #2b4999);
  z-index: 7153;
  max-width: 1140px;
  box-shadow: none;
  margin: 0 auto;
  min-height: 100%;
}

.nav-item-text {
  object-fit: contain;
  font-family: AppleSDGothicNeo, serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.navbar-collapse {
  /*line-height: 92px;*/
  /*padding-top: 12px;*/
}

.text-black {
  color: #1c396e !important;
}

.login-email {
  color: black;
  margin: 0;
  font-size: 13px;
  font-weight: bold
}

.login-btn {
  width: 100px;
  border-radius: 10px;
  background-color: #fddc27;
  height: 40px;
  font-family: AppleSDGothicNeo, serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: #284081;
}

.drop-item-custom {
}

.drop-item-custom strong {
  font-size: 20px;
}

.drop-menu-custom {
  padding: 0;
  text-align: center;
  width: 210px;
}
</style>
