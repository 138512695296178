<template>
  <div>
    <Nav/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
export default {
  name: "Main",
  components: {Footer, Nav},
  comments: {
  }
}
</script>

<style>
body{
  min-width: 530px;
}
</style>